import React, { Fragment, useEffect, useState } from 'react'
import { SFSForm } from '@newageerp/v3.templates.json-form';
import validator from '@rjsf/validator-ajv8';
import { axiosInstance } from '../api/config';

const schema = {
    "title": "AUTH SETTINGS",
    "type": "object",
    "required": [
        "title",
    ],
    "properties": {
        "title": {
            "type": "string",
            "title": "title",
        },
        "accentColor": {
            "type": "string",
            "title": "accentColor",
        },
        "backgroundColor": {
            "type": "string",
            "title": "backgroundColor"
        },
        "allowRegistration": {
            "type": "boolean",
            "title": "allowRegistration"
        },
        "allowPasswordRemind": {
            "type": "boolean",
            "title": "allowPasswordRemind"
        },
        "allowRemindMe": {
            "type": "boolean",
            "title": "allowRemindMe"
        },
        "signUpShowFirstName": {
            "type": "boolean",
            "title": "signUpShowFirstName"
        },
        "signUpShowLastName": {
            "type": "boolean",
            "title": "signUpShowLastName"
        },
        "signUpShowUsername": {
            "type": "boolean",
            "title": "signUpShowUsername"
        },
        "privacyPolicyUrl": {
            "type": "string",
            "title": "privacyPolicyUrl",
        },
        "lang": {
            "type": "string",
            "title": "lang",
            "enum": ["lt", "en"]
        },
        "design": {
            "type": "string",
            "title": "design",
            "enum": ["v1", "v2"]
        },
        "imageLeft": {
            "type": "string",
            "title": "imageLeft",
        },
        "imageTop": {
            "type": "string",
            "title": "imageTop",
        },
        "imageBottom": {
            "type": "string",
            "title": "imageBottom",
        }
    }
}

export default function AuthConfigForm() {
    const [formData, setFormData] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axiosInstance.get('settings-config').then((res) => {
            setFormData(res.data);
            setLoaded(true);
        })
    }, []);


    const saveData = (formData: any) => {
        axiosInstance.post('settings-config', formData).then((res) => {
            window.location.reload();
        })
    }

    if (!loaded) {
        return <Fragment />
    }

    return (
        <div>
            <SFSForm
                schema={schema}
                validator={validator}
                onSubmit={e => {
                    saveData(e.formData)
                }}
                formData={formData}
            />
        </div>
    )
}

import React, { useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import InputSign from "../../components/inputSign/InputSign";
import ModalForgotPassword from "../../components/modalForgotPassword/ModalForgotPassword";
import { useTranslation } from "react-i18next";
import TopLogo from '../../components/UI/TopLogo';
import Wrapper from '../../components/UI/Wrapper';
import { doLogin } from '../../../api/front-api';
import { useDataProvider } from '../../../components/DataProvider';

function SignIn() {
    let [searchParams] = useSearchParams();

    const { AllowRemindMe, AllowPasswordRemind, AllowRegistration, ImageBottom, AccentColor } = useDataProvider();

    const { t } = useTranslation();

    const [isWrongCredentials, setIsWrongCredentials] = useState(false);

    const [error, setError] = useState('');

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)

    const openForgotPassword = () => {
        if (forgotPassword === false) {
            setForgotPassword(true)
        }
        if (forgotPassword === true) {
            setForgotPassword(false)
        }
    }

    const onSubmit = () => {
        const c = searchParams.get('callback');
        doLogin(login, password, setError, !!c && typeof c === 'string' ? c : '/');
    }

    return (
        <Wrapper>
            <div className={'tw3-w-full lg:tw3-w-[350px] xl:tw3-w-[450px] tw3-space-y-[24px]'}>
                <TopLogo />
                <div className={'tw3-text-[28px]'}>{t('Prisijunkite')}</div>

                <InputSign onEnter={onSubmit} title={t('Vartotojas')} value={login} handleChange={setLogin} isWrongCredentials={isWrongCredentials} />
                <InputSign onEnter={onSubmit} title={t('Slaptažodis')} type={"password"} value={password} handleChange={setPassword} isWrongCredentials={isWrongCredentials} />

                {(!!AllowPasswordRemind || !!AllowRemindMe) &&
                    <div className="tw3-flex tw3-items-center tw3-justify-between">
                        {!!AllowRemindMe &&
                            <div className="tw3-flex tw3-items-center">
                                <input id="remindMe" name="remindMe" type="checkbox" className={'tw3-w-[21px] tw3-h-[21px]'} />
                                <label htmlFor='remindMe' className={"tw3-pl-[8px] tw3-text-[14px] tw3-font-normal"}>{t('Prisiminti')}</label>
                            </div>
                        }
                        {!!AllowPasswordRemind &&
                            <div
                                className={'tw3-cursor-pointer'} onClick={() => openForgotPassword()}
                                style={{
                                    color: AccentColor,
                                }}
                            >
                                {t('Pamiršote slaptažodį?')}
                            </div>
                        }
                    </div>
                }

                {!!error && <div className='tw3-duration-200 tw3-bg-red-50 tw3-text-red-700 tw3-p-2 tw3-rounded-md'>{t(error)}</div>}

                <button
                    onClick={onSubmit}
                    className={'tw3-w-[100%] tw3-py-[16px] tw3-text-[#ffffff]'}
                    style={{
                        backgroundColor: AccentColor,
                    }}
                >
                    {t('Prisijungti')}
                </button>

                {!!AllowRegistration &&
                    <div
                        // className={'tw3-text-[#0EA5E9]'}
                        style={{
                            color: AccentColor,
                        }}
                    >
                        <Link to={`${process.env.REACT_APP_PUBLIC_URL}sign-up`} >{t('Sukurti paskyrą')}</Link>
                    </div>
                }

                <div
                    className={'tw3-border-t-[1px] tw3-opacity-30'}
                    style={{
                        borderColor: AccentColor
                    }}

                ></div>

                <div className="tw3-flex tw3-gap-2 tw3-items-center">
                    {ImageBottom.map(url => <img src={url} alt="" />)}
                </div>
            </div>
            {
                forgotPassword
                    ? <ModalForgotPassword setForgotPassword={setForgotPassword} />
                    : ''
            }
        </Wrapper>
    );
}

export default SignIn;
